import { FirebaseError } from 'firebase/app'
import { GetMeDocument } from '~/gql/graphql'
import { useMainStore } from '~/store'

// middleware/auth.ts

export function createDebounce(): (fnc: any, delayMs?: number) => void {
    let timeout: any = null
    return function (fnc: any, delayMs?: number) {
        clearTimeout(timeout)
        timeout = setTimeout(() => {
            fnc()
        }, delayMs || 300)
    }
}

export function toTitleCase(str: string) {
    return str.replace(
        /\w\S*/g,
        (txt) => {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
        },
    )
}


export function highlightsToHTML(searchResult: any) {
    let result = ''
    for (const highlight of searchResult.highlights)
        result += `<span class="text-gray-400">${highlight.fieldName}: </span> <span class="">${highlight.html.replaceAll('<p>', ' ').replaceAll('</p>', ' ')}</span>&nbsp;`

    return result
}

export function baseColorForParty(party: string) {
    party = party.toLowerCase()
    switch (party) {
        case 'republican':
            return 'red'
        case 'democrat':
        case 'democratic':
            return 'blue'
        case 'independent':
            return 'green'
        default:
            return 'green'
    }
}

export function colorForParty(party: string) {
    party = party.toLowerCase()
    switch (party) {
        case 'republican':
            return 'bg-red-700'
        case 'democrat':
        case 'democratic':
            return 'bg-blue-700'
        case 'independent':
            return 'bg-green-700'
        default:
            return 'bg-gray-500'
    }
}

export function textColorForParty(party: string) {
    party = party.toLowerCase()
    switch (party) {
        case 'republican':
            return 'text-red-700'
        case 'democrat':
        case 'democratic':
            return 'text-blue-700'
        case 'independent':
            return 'text-gray-800'
        default:
            return 'text-gray-800'
    }
}

export function groupBy<T, K extends keyof any>(arr: T[], key: (i: T) => K) {
    return arr.reduce((groups, item) => {
        (groups[key(item)] ||= []).push(item)
        return groups
    }, {} as Record<K, T[]>)
}


export function getFirebaseErrorMessage(reason: FirebaseError): string {
    let errorMessage = "There was an error logging in. Please try again later.";
    switch (reason.code) {
        case "auth/weak-password":
            errorMessage =
                "Your password is too weak. Please use a stronger password.";
            break;
        case "auth/invalid-email":
            errorMessage = "Your email address is invalid. Please try again.";
            break;
        case "auth/email-already-in-use":
            errorMessage =
                "Email address is already in use on a different account. Please log in or use a different email address.";
            break;
        case "auth/invalid-credential":
            errorMessage = "Your email address is invalid";
            break;
        case "auth/user-disabled":
            errorMessage =
                "Your account has been disabled. Please contact support.";
            break;
        case "auth/user-not-found":
            errorMessage =
                "No account found with that email address. Please check the spelling or sign up for an account if you haven't already done so.";
            break;
        case "auth/wrong-password":
            errorMessage = "Wrong password. Please try again.";
            break;
        case "auth/too-many-requests":
            errorMessage = "Too many requests";
            break;
        case "auth/operation-not-allowed":
            errorMessage = "Operation not allowed";
            break;
        case "auth/invalid-action-code":
            errorMessage = "This login link is invalid or expired. Please request a new one.";
            break;
    }
    console.log(reason.code, errorMessage); 
    return errorMessage;
}